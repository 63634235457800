<template>
  <a-modal v-model="visible" :title="form.ru.name" :afterClose="close">
    <template slot="footer">
      <a-button key="back" @click="close">
        Отмена
      </a-button>
      <a-button key="submit" :disabled="isDisabled" type="primary" @click.prevent.stop="submit">
        {{ this.form.id ? 'Сохранить' : 'Создать' }}
      </a-button>
    </template>
    <a-form-model ref="form" :model="form">
      <a-form-model-item label="Название(ru)">
        <a-input v-model="form.ru.name" required/>
      </a-form-model-item>
      <a-form-model-item label="Название(ua)">
        <a-input v-model="form.ua.name" required/>
      </a-form-model-item>
      <a-form-model-item label="Позиция">
        <a-input v-model="form.position" required/>
      </a-form-model-item>
      <a-form-model-item label="Статус" required>
        <a-select v-model="form.active" placeholder="выберите статус">
          <a-select-option v-for="type in statuses" :key="type.id" :value="type.id">
            {{ type.name }}
          </a-select-option>
        </a-select>
      </a-form-model-item>
    </a-form-model>
  </a-modal>
</template>

<script>
export default {
  name: 'EditNewsTagModal',
  data() {
    return {
      visible: false,
      statuses: [
        {
          name: 'Активная',
          id: 1,
        },
        {
          name: 'Не активная',
          id: 0,
        },
      ],
      form: {
        ru: {
          name: '',
        },
        ua: {
          name: '',
        },
      },
    }
  },
  computed: {
    isDisabled() {
      return false
    },
  },
  methods: {
    open(record = null) {
      if (record) {
        this.form = record
      }
      this.visible = true
    },
    async submit() {
      try {
        if (this.form.id) {
          await this.$services.put(`admin/tags/${this.form.id}`, this.form)
        } else {
          await this.$services.post('admin/tags', this.form)
        }
        this.close()
      } catch (e) {
        console.log(e)
      }
    },
    close() {
      this.visible = false
      this.$emit('getList')
      this.form = {
        ru: {
          name: '',
        },
        ua: {
          name: '',
        },
      }
    },
  },
}
</script>

<style scoped>

</style>
