<template>
  <div>
    <div class="cui__utils__heading">
      <strong>Контент: Новости</strong>
    </div>
    <div class="card">
      <div class="card-header card-header-flex">
        <div class="d-flex flex-column justify-content-center mr-auto">
          <h5 class="mb-0">Рубрики новостей</h5>
        </div>
        <div class="d-flex flex-column justify-content-center ml-3">
          <a class="btn btn-primary" @click="$refs.editModal.open()">Добавить</a>
        </div>
      </div>
      <div class="card-body">
        <news-tags-table :list="list" @getList="getList" :paginate="pagination" @handleTableChange="handleTableChange" />
      </div>
    </div>
    <edit-news-tag-modal ref="editModal" @getList="getList" />
  </div>
</template>

<script>
import NewsTagsTable from '@/components/content/news/NewsTagsTable'
import EditNewsTagModal from '@/components/content/news/EditNewsTagModal'

export default {
  name: 'index',
  data() {
    return {
      list: [],
      pagination: {},
    }
  },
  created() {
    this.getList()
  },
  methods: {
    async getList() {
      try {
        const tags = (await this.$services.get('admin/tags')).data.data.values
        this.list = tags.data
        this.pagination = {
          current: tags.pagination.current_page,
          total: tags.pagination.total,
          pageSize: tags.pagination.per_page,
        }
      } catch (e) {
        console.log(e)
      }
    },
    handleTableChange(pagination) {
      this.pagination.current = pagination.current
      this.getList()
    },
    removeItem() {},
  },
  components: { NewsTagsTable, EditNewsTagModal },
}
</script>

<style scoped>

</style>
